<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="120px">
<el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="用户ID" prop="dataId">
            <el-input v-model="form.dataId" placeholder="请选择老人" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectPersonId()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="idNum">
            <el-input v-model="form.idNum" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
         <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户类型" prop="userType">
            <el-select v-model="form.userType" placeholder="请选择用户类型" style="width: 100%">
              <el-option v-for="item in userTypeList" :key="item.typeCode" :label="item.typeName" :value="item.typeCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="登录用户名" prop="userName">
            <el-input v-model="form.userName" placeholder="请输入登录用户名" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" placeholder="请输入密码" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择人员" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
         <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="search" @reset="searchReset">
        <vxe-form-item title="老人姓名" span="12">
          <vxe-input v-model="searchData.personName" placeholder="请输入老人姓名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="联系电话" span="12">
          <vxe-input v-model="searchData.tel" placeholder="请输入联系电话" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="身份证号" span="12">
          <vxe-input v-model="searchData.idNumber" placeholder="请输入身份证号" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="12">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; "
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" width="100" label="姓名" align="center"></el-table-column>
            <el-table-column prop="sex" label="性别" width="60" align="center"></el-table-column>
            <el-table-column prop="idNum" label="身份证" width="180" align="center"></el-table-column>
            <el-table-column prop="tel" label="联系方式" width="120" align="center"></el-table-column>
            <el-table-column prop="address" label="地址" align="center"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[5,10,20]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      userTypeList: [],
      radio: '',
      height: 240,
      tableData: [],
      visible: false,
      searchData: {
        personName: '',
        idNumber:'',
        tel:''
      },
      titleName: '',
      form: {
        viewAllData: true,
      },
      rules: {
        userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        userType: [{ required: true, message: '请选择用户类型', trigger: 'blur' }],
      },
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 5,
        align: 'left',
        pageSizes: [5,10, 20],
        perfect: true,
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addSystemUser', 'searchPerson','getUserTypesServe','addSystemAppUser']),
    initView() {
      this.getUserTypeLists()
      this.getDetail()
      this.getConsConsultant()
    },
    getUserTypeLists() {
      this.getUserTypesServe({
        type: 3
      }).then(res => {
        if (res.code == 200) {
          this.userTypeList = res.data
          this.$forceUpdate()
        }
      })
    },
    getDetail() {
      this.titleName = '添加用户'
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addSystemAppUser'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.visible = false
    },
    getCurrentRow(row) {
      this.form.dataId = row.id
      this.form.idNum = row.idNum
      this.form.name = row.name
      if(row.tel){
        this.form.userName = row.tel
      }
      this.$forceUpdate()
    },
    cancel() {
      this.visible = false
    },
    search() {
      this.getConsConsultant()
    },

    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getConsConsultant()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          personName: this.searchData.personName,
          idNumber: this.searchData.idNumber,
          tel: this.searchData.tel,
          skipValidate: true,
          seachCmn:true,
        },
      }
      let server = 'searchPerson'
      this[server](params).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    selectPersonId() {
      this.visible = true
    },
    searchReset() {
      this.searchData = {}
      this.getConsConsultant()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
